:root {
  --color-one: #3A5DAE;
  --color-one-hover: #033d83;
  --color-two: #4FAE47;
  --color-two-hover: #36823e;
  --color-three: #24292E;
  --color-four: #F5F5F5;
  --color-danger: #d30425;
}


//scrollbar
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--bs-gray-500);
  border-radius: 0;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

app-header {
  flex: initial;
}

app-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.outlet-container {
  height: 100%;
  overflow-y: auto;

  & > *:not(router-outlet) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.page-container {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

//bootstrap

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  height: 54px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.chat-sidebar {
  @extend .sidebar-w;
  top: 55px;
  height: calc(100vh - 55px);
}

.text-container {
  background: var(--color-four) 0 0 no-repeat padding-box;
  box-shadow: 0 6px 16px #00000040;
  border: 1px solid #D8D8D8;
  border-radius: 20px;
  opacity: 1;
  padding: 1.5rem 2rem;
}

.text-area{
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  outline: none;
  box-shadow: none;
  resize: none;
}

/* markdown */
markdown  {
  p {
    margin-block-start: 0 !important;
  }

  p:not(last-child) {
    margin-block-end: 0.3em !important;
  }

  p:last-child{
    margin-block-end: 0!important;
  }

  td {
    border: 1px solid #777;
    padding: 0.5rem;
    text-align: center;
    background: white;
  }

  th {
    border: 1px solid #777;
    padding: 0.5rem;
    text-align: center;
    background: lightgray;
  }

  table {
    border-collapse: collapse;
    margin: 1rem;
  }

  tbody tr:nth-child(odd) {
    background: white;
  }
  caption {
    font-size: 0.8rem;
  }

  code {
    white-space: break-spaces !important;
  }

  .citation-in-text {
    background: var(--indigo-100);
    padding: 2px;
    border-radius: 3px;
    color: var(--indigo-700);
    font-weight: 500;
    font-size: 0.75rem;
  }

}

.align-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.sidebar-w {
  width: 18.5%;
}

.markdown-clipboard-toolbar {
  top: 0.2rem !important;
}

.markdown-clipboard-button {
  color: var(--primary-color);
  background: none;
  font-family: inherit;
  font-size: 0.75rem;
  border: none;
}

.markdown-clipboard-button:hover {
  cursor: pointer;
  background: var(--gray-800)
}

.markdown-clipboard-button .copied {
  color: green;
}

.markdown-code-header {
  height: 2rem;
  margin: 0 -1rem 1rem -1rem;
  padding-left: 1rem;
  padding-top: 0.25rem;
  color: var(--blue-400);
  background: var(--blue-800);
}

.upload-button {
  min-width: 48px;
  height: 48px;
  margin: 4px;

  .pi {
    font-size: 1.5rem;
  }
}

.bordered-container {
  padding: 1rem 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--surface-border);
}

.spinner-xs {
  width: 1.25rem;
  height: 1.25rem;
}

.filechat-icon {
  display: inline-block;
  width: 4.65rem;
  height: 4rem;
  background-size: cover;
}

.filechat-icon-enabled {
  @extend .filechat-icon;
  background-image: url('assets/img/filechat.png');
}

.filechat-icon-disabled {
  @extend .filechat-icon;
  background-image: url('assets/img/filechat_gray.png');
}


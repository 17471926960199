app-chat {
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: rgb(206, 212, 218);
  }

  p-sidebar .p-button.p-button-icon-only {
    padding: 0 !important;
  }
}

.p-inputnumber {
  width: 100%;
}
app-settings{
  .p-dropdown{
    width: 100%;
  }
}
